import { Link } from '@inertiajs/react';
import { Box, Button, Container, rem, Stack, Text, Title } from '@mantine/core';

import FaqComponent from '@/pages/site/home/components/FaqComponent.jsx';

export default function HomeFaq(props) {
  const faq = [
    {
      label: 'How does fractional ownership work?',
      description:
        'Fractional ownership allows multiple users to collectively own a single artwork with general partnership. Each customer purchases a share of the artwork, proportional to their investment amount. This model makes art investment more accessible, as customers can participate with smaller amounts of capital.',
    },
    {
      label: 'How are my shares secured on Goldframer?',
      description:
        'At Goldframer, your shares are secured through blockchain technology and smart contracts. Each artwork is representing ownership on the blockchain. This ensures transparent and immutable records of ownership, providing security and trust for our customers.',
    },
    {
      label: 'How does Goldframer select artworks?',
      description:
        'Goldframer collaborates with experts in the Islamic art field to curate a selection of high-quality artworks with appreciation potential. We conduct thorough due diligence to ensure that each artwork meets our standards for profitability. Additionally, we prioritize artworks that align with Islamic values and cultural significance.',
    },
  ];

  return (
    <Box id={'faq'}>
      <Container py={100} {...props}>
        <Stack>
          <Title align={'center'} lh={1.2} fw={500} fz={rem(48)} lts={rem(-3)} c={'gfdark.9'}>
            Frequently Asked Questions
          </Title>
          <FaqComponent faq={faq} />
        </Stack>
        <Button component={Link} href={route('site.faq')} variant="default" size="lg" mt={'xl'}>
          <Text size="lg">More questions?</Text>
        </Button>
      </Container>
    </Box>
  );
}
